import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

type SectionProps = {
  backgroundImage: string;
};

export const Section = styled.div`
  overflow: hidden;
  background-color: #020202;
  padding: 32px 0;

  .content-img {
    margin-bottom: 10px;

    img {
      height: 151px;
      max-width: 100%;
    }
  }

  .content-info {
    h2 {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      color: #ffffff;
      margin-bottom: 16px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: var(--gray200);
      margin: 0;
    }

    .content-button {
      margin-top: 24px;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 48px 0;
    background-image: ${({ backgroundImage }: SectionProps) =>
      `url(${backgroundImage})`};
    background-position: top left;
    background-size: 350px;
    background-repeat: no-repeat;

    .content {
      display: flex;
      .content-img {
        flex-basis: 50%;
        img {
          display: none;
        }
      }
      .content-info {
        flex-basis: 50%;
      }
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    background-size: contain;
    .content {
      align-items: center;
      .content-img {
        flex-basis: 58%;
      }
      .content-info {
        flex-basis: 42%;
        h2 {
          font-size: 28px;
          line-height: 33px;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    padding: 96px 0;
    .content {
      align-items: center;
      .content-img {
        flex-basis: 60%;
      }
      .content-info {
        flex-basis: 40%;
        h2 {
          font-size: 48px;
          line-height: 52px;
        }
        p {
          font-size: 18px;
          line-height: 21px;
        }
      }
    }
  }
`
