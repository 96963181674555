import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.div`
  overflow: hidden;
  background-color: #020202;
  padding: 48px 0;
  .content-img {
    margin-bottom: 24px;
    img {
      height: 240px;
      max-width: 100%;
    }
  }

  .content-info {
    h2 {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      color: #ffffff;
      margin-bottom: 16px;
    }

    h3 {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      color: #ffffff;
      margin-bottom: 16px;
    }

    ul {
      padding-left: 25px;
      color: #ffffff;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      color: #ffffff;
      margin: 0;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 46px 0;
    .content {
      display: flex;
      .content-img {
        flex-basis: 50%;
        img {
          height: 261px;
        }
      }
      .content-info {
        flex-basis: 50%;
      }
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    .content {
      align-items: center;

      .content-img {
        img {
          height: 289px;
        }
      }

      .content-info {
        h2 {
          font-size: 28px;
          line-height: 33px;
        }

        h3 {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    padding: 96px 0;

    .content {
      align-items: center;

      .content-img {
        flex-basis: 45%;

        img {
          height: 325px;
        }
      }

      .content-info {
        flex-basis: 55%;

        h2 {
          font-size: 48px;
          line-height: 52px;
        }

        h3 {
          font-size: 28px;
          line-height: 33px;
        }
      }
    }
  }
`
