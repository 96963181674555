import React from 'react'

import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'

import QRcodeDividaZero from '../_assets/QRcode-divida-zero.png'
import QRcodeFGTS from '../_assets/QRcode-FGTS.jpg'
import QRcodeHomeEquity from '../_assets/QRcode-home-equity.jpg'
import QRcodeConsignado from '../_assets/RQcode-Consignado.jpg'

const title = `
  <p style="font-size: 16px; color: var(--gray400)">Está no computador?</p>
  <p style="color: var(--gray500);font-size: 24px;line-height: 30px;max-width: 344px;">
    Aponte a câmera do seu celular para o QRCode para acessar o
    <span style="color: var(--orange500)">Super App</span>
    no seu dispositivo.
  </p>
`

type ModalDividaZeroProps = {
  type?: TypeQrcode;
  isOpen: boolean;
  openModal: (value: boolean) => void;
}

const imgArray = {
  DividaZero: QRcodeDividaZero,
  FGTS: QRcodeFGTS,
  HomeEquity: QRcodeHomeEquity,
  Consignado: QRcodeConsignado,
}

const ModalDividaZero = ({ openModal, isOpen, type = QRcodeDividaZero }: ModalDividaZeroProps) => {
  return typeof window !== 'undefined' ? (
    <Modal isModalOpen={isOpen} setIsModalOpen={openModal} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={openModal}
        title={title}
        instructions=''
        qrCode={imgArray[type]}
        dataLayer={{
          section: 'dobra_01',
          section_name: 'Modal Divida Zero',
          element_action: 'click button',
          element_name: 'Modal Divida Zero',
        }}
      />
    </Modal>
  ) : null
}

export default ModalDividaZero
