import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

import Abstract from '../../_assets/Abstract.png'

export const Section = styled.div`
  overflow: hidden;
  background-color: #020202;
  padding: 28px 0;

  .content-title {
    h2 {
      font-weight: 500;
      line-height: 28px;
      font-size: 24px;
      color: #FFFFFF;

      span {
        color: var(--orange500);
      }
    }

    h3 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      color: #FFFFFF;
      margin: 0;
    }
  }

  .content-mosaico {
    gap: 40px;
    padding: 40px 0;
    display: flex;
    flex-direction: column;

    .mosaico-card {
      background-size: contain;
      width: 270px;
      height: 140px;

      margin: auto;

      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      background-image: url(${Abstract});
      background-position: center center;
      background-repeat: no-repeat;

      p {
        text-align: center;
        margin: 0;
        font-size: 20px;
        line-height: 25px;
        color: #FFFFFF;
      }

      img {
        position: absolute;
      }
    }

    .mosaico-1 {
      .star-1 {
        top: -15px;
        left: 17%;
      }

      .star-2 {
        bottom: -15px;
        right: 10%;
      }

      p {
        width: 200px;
      }
     }

    .mosaico-2 {
      .star-1 {
        top: -15px;
      }

      p {
        width: 170px;
      }
    }

    .mosaico-3 {
      .star-1 {
        left: -17px;
        bottom: 10%;
      }

      p {
        width: 130px;
      }
    }

    .mosaico-4 {
      .star-1 {
        top: -15px;
        right: 20%;
      }

      .star-2 {
        bottom: -15px;
        left: 25%;
      }

      p {
        width: 130px;
      }
    }
  }

  @media(min-width: 359px) {
    .content-mosaico {
      .mosaico-card {
        width: 320px;
        height: 160px;
        background-size: cover;
      }
    }
  }

  @media(min-width: ${breakpoints.md}) {
    padding: 48px 0;

    .content-title {
      h2 {
        font-size: 28px;
        line-height: 33px
      }

      h3 {
        font-size: 24px;
        line-height: 28px;
      }
    }

    .content-mosaico {
      flex-flow: row wrap;

      .mosaico-card {
        width: 100%;
        height: 200px;
        background-size: 100% 100%;
      }

      .mosaico-1 {
        flex-basis: calc(55% - 20px);
        .star-1 {
          left: auto;
          top: -17px;
        }

        .star-2 {
          bottom: -17px;
        }
      }

      .mosaico-2 {
        flex-basis: calc(45% - 20px);

        .star-1 {
          right: 35%;
          top: - 17px
        }
      }

      .mosaico-3 {
        flex-basis: calc(45% - 20px);
      }

      .mosaico-4 {
        flex-basis: calc(55% - 20px);

        .star-1 {
          top: -17px;
          right: auto;
        }

        .star-2 {
          bottom: -17px;
        }

        p {
          width: auto;
        }
      }
    }
  }

  @media(min-width: ${breakpoints.lg}) {
    .content-title {
      h2 {
        font-size: 40px;
        line-height: 44px
      }

      h3 {
        font-size: 28px;
        line-height: 33px;
      }
    }

    .content-mosaico {
      .mosaico-card {
        p {
          font-size: 24px;
          line-height: 30px;
        }
      }

      .mosaico-1 {
        flex-basis: calc(60% - 20px);

        .star-1 {
          left: 35%;
        }

        .star-2 {
          right: 20%;
        }

        p {
          width: auto;
        }
      }

      .mosaico-2 {
        flex-basis: calc(40% - 20px);

        p {
          width: 200px;
        }
      }

      .mosaico-3 {
        p {
          width: 200px;
        }
      }

      .mosaico-4 {
        .star-1 {
          right: 40%;
        }
      }
    }
  }

  @media(min-width: ${breakpoints.xl}) {
    padding: 96px 0;

    .content-title {
      h2 {
        font-size: 48px;
        line-height: 52px
      }
    }
  }
`
