import React from 'react'
import * as S from './style'
import ButtonApp from '../../_components/_ButtonApp'

const imageMoble = 'https://central-imagens.bancointer.com.br/images-without-small-versions/neg-sobra3-360/image.webp'
const imageDesktop = 'https://central-imagens.bancointer.com.br/images-without-small-versions/neg-dobra3-web/image.webp'

type OfertaIdealProps = {
  openModal: () => void;
}

const OfertaIdeal = ({ openModal }: OfertaIdealProps) => {
  return (
    <S.Section backgroundImage={imageDesktop}>
      <div className='container'>
        <div className='content'>
          <div className='content-img'>
            <img src={imageMoble} alt='Feirão da dívida zero - Inter' />
          </div>
          <div className='content-info'>
            <h2 className='font-citrina'>
              Oferta ideal para sua reorganização financeira
            </h2>
            <p>
              Preparamos condições exclusivas para você ficar em dia. Simule uma proposta e saiba o quanto você vai economizar.
            </p>
            <div className='content-button'>
              <ButtonApp
                url='bancointer://home?utm_campaign=lp_feiraodividazero_2024'
                title='Simular agora'
                textButton='Simular agora'
                openModal={openModal}
                dataLayer={{
                  section: 'dobra_03',
                  section_name: 'Feirão Dívida Zero 2024',
                  element_action: 'click button',
                  element_name: 'Simular agora',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}
export default OfertaIdeal
