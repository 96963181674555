import React, { useState } from 'react'

import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import ModalDividaZero from './_components/_ModalDividaZero'
import Hero from './sections/hero/_index'
import OfertaEspeciais from './sections/ofertas-especiais/_index'
import OfertaIdeal from './sections/oferta-ideal/_index'
import SemDinheiro from './sections/sem-dinheiro/_index'
import DuvidasNegociar from './sections/duvidas-negociar/_index'

const FeiraoDividaZero = () => {
  const [ isOpenModal, setIsOpenModal ] = useState<boolean>(false)
  const [ typeQrCode, setTypeQrCode ] = useState<TypeQrcode>('DividaZero')

  const handleOpenModal = (typeQrcode?: TypeQrcode) => {
    setTypeQrCode(typeQrcode as TypeQrcode)
    setIsOpenModal(true)
  }

  return (
    <Layout pageContext={pageContext}>
      <ModalDividaZero openModal={setIsOpenModal} isOpen={isOpenModal} type={typeQrCode} />
      <Hero openModal={() => handleOpenModal('DividaZero')} />
      <OfertaEspeciais openModal={() => handleOpenModal('DividaZero')} />
      <OfertaIdeal openModal={() => handleOpenModal('DividaZero')} />
      <SemDinheiro openModal={handleOpenModal} />
      <DuvidasNegociar />
    </Layout>
  )
}

export default FeiraoDividaZero
