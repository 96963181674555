import React from 'react'
import * as S from './style'

import ButtonApp from '../../_components/_ButtonApp'
import Star from '../../_assets/Star.png'

type HeroProps = {
  openModal: () => void;
}

const OfertaEspeciais = ({ openModal }: HeroProps) => {
  return (
    <S.Section>
      <div className='container'>
        <div className='content'>
          <div className='content-title'>
            <h2 className='font-citrina'>
              No Feirão da Dívida Zero, você resolve sua dívida 100% online com <span>ofertas especiais</span>.
            </h2>
            <h3 className='font-citrina'>
              Veja tudo que pode ser negociado
            </h3>
          </div>
          <div className='content-mosaico'>
            <div className='mosaico-1'>
              <div className='mosaico-card'>
                <img src={Star} className='star-1' />
                <img src={Star} className='star-2' />
                <p>Dívida do cartão de crédito</p>
              </div>
            </div>
            <div className='mosaico-2'>
              <div className='mosaico-card'>
                <img src={Star} className='star-1' />
                <p>Dívida do cartão de crédito PJ</p>
              </div>
            </div>
            <div className='mosaico-3'>
              <div className='mosaico-card'>
                <img src={Star} className='star-1' />
                <p>Crédito Consignado</p>
              </div>
            </div>
            <div className='mosaico-4'>
              <div className='mosaico-card'>
                <img src={Star} className='star-1' />
                <img src={Star} className='star-2' />
                <p>Crédito Imobiliário</p>
              </div>
            </div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <ButtonApp
              url='bancointer://home?utm_campaign=lp_feiraodividazero_2024'
              title='Negocie agora'
              textButton='Negocie agora'
              openModal={openModal}
              dataLayer={{
                section: 'dobra_02',
                section_name: 'Escolha Inteligente é recuperar sua vida financeira',
                element_action: 'click button',
                element_name: 'Negocie agora',
              }}
            />
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default OfertaEspeciais
