import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

type ButtonAppProps = {
  url: string;
  title: string;
  textButton: string;

  dataLayer: IDataLayerParams;
  openModal: () => void;
}

const ButtonApp = ({ url, title, textButton, dataLayer, openModal }: ButtonAppProps) => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClick = () => {
    sendDatalayerEvent(dataLayer)
  }

  if (width < WIDTH_MD) {
    return (
      <a
        href={url}
        title={title}
        className='btn btn--lg bg-orange--base text-white text-none'
        onClick={handleClick}
      >
        {textButton}
      </a>
    )
  }

  return (
    <button
      title={title}
      className='btn btn--lg bg-orange--base text-white text-none mt-md-2'
      style={{ maxWidth: '100%!important' }}
      onClick={() => {
        openModal()
        handleClick()
      }}
    >
      {textButton}
    </button>
  )
}

export default ButtonApp
