import React from 'react'
import * as S from './style'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD, WIDTH_LG } from 'src/utils/breakpoints'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import CardCarousel from '../../_components/_CardCarousel'

const imageDesk = 'https://central-imagens.bancointer.com.br/images-without-small-versions/neg-dobra4-web/image.webp'
const imageMob = 'https://central-imagens.bancointer.com.br/images-without-small-versions/neg-dobra4-360/image.webp'

type CardCarouselProps = {
  title: string;
  description: string;
  link: string;
  type: string;
}

const dataCarousel = [
  {
    title: 'Empréstimo consignado',
    description: 'Utilize uma das linhas de crédito do Inter pra negociar a dívida pagando menos juros',
    link: 'https://intergo.app/2f7d6d34',
    type: 'Consignado',
  },
  {
    title: 'Antecipe seu FGTS',
    description: 'Aproveite um dinheiro que já é seu para negociar sua dívida',
    link: 'https://intergo.app/75bcdf61',
    type: 'FGTS',
  },
  {
    title: 'Empréstimo com garantia de imóvel',
    description: 'Utilize seu imóvel como garantia pra ficar em dia',
    link: 'https://intergo.app/8ceb1e4f',
    type: 'HomeEquity',
  },
]

type SemDinheiroProps = {
  openModal: (type: TypeQrcode) => void;
}

const SemDinheiro = ({ openModal }: SemDinheiroProps) => {
  const width = useWidth()

  return (
    <S.Section>
      <div className='container'>
        <div className='content'>
          <div className='content-top'>
            <div className='content-img'>
              {width >= WIDTH_MD && width < WIDTH_LG ? (
                <img src={imageDesk} alt='Pessoa segurando o celular' />
              ) : (
                <img src={imageMob} alt='Pessoa segurando o celular' />
              )}
            </div>
            <div className='content-info'>
              <h2 className='font-citrina'>Tá sem dinheiro pra negociar agora?</h2>
              <p>Tenha dinheiro em mãos com juros mais baratos que os da dívida do cartão de crédito.</p>
              <p>Aproveite as facilidades do nosso Super App pra não perder a oportunidade do Feirão.</p>
            </div>
          </div>
          <div className='content-carousel'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 2 }}
              lg={{ items: 3 }}
              xl={{ items: 3 }}
            >
              {dataCarousel.map((item: CardCarouselProps) => (
                <CardCarousel data={item} key={item.type} openModal={openModal} />
              ))}
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default SemDinheiro
