import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.div`
  overflow: hidden;

  background-color: #020202;
  padding: 24px 0;

  .content-img {
    margin-bottom: 24px;
    text-align: center;

    img {
      height: 154px;
      max-width: 100%;
    }
  }

  .content-info {
    h2 {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      color: #ffffff;
      margin-bottom: 32px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: var(--gray200);
      margin: 0;
      &:first-of-type {
        margin-bottom: 16px;
      }
    }
  }

  .content-carousel {
    .card-carousel-space {
      padding: 0 5px;
    }

    .card-carousel {
      background-color: #3c3331;
      padding: 32px 24px;
      position: relative;
      border-radius: 16px;
      height: 240px;

      h3 {
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        color: #ffffff;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: #ffffff;
      }

      .carousel-button {
        position: absolute;
        bottom: 24px;
        right: 24px;

        a {
          display: flex;
          align-items: center;
        }

        p {
          font-size: 20px;
          line-height: 24px;
          font-weight: 500;
          color: var(--orange500);
          margin: 0;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.md}) {
    .card-carousel-space {
      padding: 0 12px;
    }

    .content-top {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
    }

    .content-img {
      flex-basis: 50%;
      text-align: right;
      margin: 0;

      img {
        height: 280px;
      }
    }

    .content-info {
      flex-basis: 50%;

      h2 {
        width: 320px;
      }
      p {
        width: 320px;
      }
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 48px 0 0 0;
    .content-img {
      img {
        height: 185px;
      }
    }

    .content-info {
      h2 {
        font-size: 40px;
        line-height: 44px;
        width: 420px;
      }
      p {
        font-size: 18px;
        line-height: 22px;
        width: 420px;
      }
    }

    .card-carousel {
      p {
        width: auto;
      }
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    padding: 105px 0 0 0;
    .content-img {
      img {
        height: 258px;
      }
    }
    .content-info {
      h2 {
        font-size: 48px;
        line-height: 48px;
        width: 420px;
      }
      p {
        font-size: 18px;
        line-height: 22px;
        width: 420px;
      }
    }

    .card-carousel {
      h3 {
        font-size: 24px;
        line-height: 30px;
      }
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .content-carousel {
      padding: 10px 0;
      .react-multi-carousel-track {
        width: 100%;
      }
    }
  }
`
