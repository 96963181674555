import React, { MouseEvent } from 'react'

import IconArrow from 'src/components/UI/MarkdownIcon'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

type CardCarouselProps = {
  data: {
    title: string;
    description: string;
    link: string;
    type: string;
  };
  openModal: (type: TypeQrcode) => void;
}

const CardCarousel = ({ data, openModal }: CardCarouselProps) => {
  const width = useWidth()

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (width > WIDTH_MD) {
      event.preventDefault()
      openModal(data.type as TypeQrcode)
    }
  }

  return (
    <div className='card-carousel-space'>
      <div className='card-carousel'>
        <h3>{data?.title}</h3>
        <p>{data?.description}</p>

        <div className='carousel-button'>
          <a href={data?.link} onClick={handleClick}>
            <p className='font-citrina'>Saiba mais</p>
            <IconArrow width='22.5' height='24.34' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
          </a>
        </div>
      </div>
    </div>
  )
}

export default CardCarousel
