import React from 'react'
import * as S from './style'

const imagePage = 'https://central-imagens.bancointer.com.br/images-without-small-versions/neg-dobra5-logo/image.webp'

const DuvidasNegociar = () => {
  return (
    <S.Section>
      <div className='container'>
        <div className='content'>
          <div className='content-img'>
            <img src={imagePage} alt='Feirão da dívida zero - Inter' />
          </div>
          <div className='content-info'>
            <h2 className='font-citrina'>
              Tem dúvidas ou quer negociar direto com um dos nossos especialistas?
            </h2>
            <h3 className='font-citrina'>
              Entre em contato pela Central de Atendimento.
            </h3>
            <ul>
              <li>
                <p>3003-4070 (capitais e regiões metropolitanas)</p>
              </li>
              <li>
                <p>0800 940 0007 (demais regiões)</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </S.Section>
  )
}
export default DuvidasNegociar
