import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

type SectionProps = {
  backgroundImage: string;
};

export const Section = styled.div`
  background-color: #020202;
  padding: 32px 0;
  .content-info {
    width: 100%;
  }

  .content-img {
    text-align: center;
    img {
      height: 195px;
      margin: auto;
    }
  }

  h1 {
    font-size: 28px;
    font-weight: 500;
    line-height: 33px;
    color: #ffffff;
    margin: 16px 0;
  }

  p {
    color: #ffffff;
    font-size: 16px;
    line-height: 19px;
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 53px 0 73px 0;
    background-image: ${({ backgroundImage }: SectionProps) =>
      `url(${backgroundImage})`};
    background-position: top right;
    background-size: cover;
    background-repeat: no-repeat;

    .content-info {
      max-width: 350px;
    }

    .content-img {
      text-align: left;
      img {
        height: 126px;
      }
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 72px 0;
    .content-info {
      max-width: 420px;
    }
    .content-img {
      img {
        height: 195px;
      }
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    padding: 95px 0;
    .content-img {
      img {
        height: 280px;
      }
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    padding: 95px 0;
    .content-img {
      img {
        height: 280px;
      }
    }
  }

  @media (min-width: ${breakpoints.xxxl}) {
    background-size: auto;
  }
`
